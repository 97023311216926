/* FONT PATH
 * -------------------------- */

 @font-face {
   font-family: 'Font Awesome 5 Free';
   font-style: normal;
   font-weight: 900;
   font-display: $fa-font-display;
   src: url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-solid-900.eot'), '#{$fa-font-path}/fa-solid-900.eot'));
   src: url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-solid-900.eot#iefix'), '#{$fa-font-path}/fa-solid-900.eot#iefix')) format('embedded-opentype'),
   url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-solid-900.woff2'), '#{$fa-font-path}/fa-solid-900.woff2')) format('woff2'),
   url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-solid-900.woff'), '#{$fa-font-path}/fa-solid-900.woff')) format('woff'),
   url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-solid-900.ttf'), '#{$fa-font-path}/fa-solid-900.ttf')) format('truetype'),
   url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-solid-900.svg#fontawesome'), '#{$fa-font-path}/fa-solid-900.svg#fontawesome')) format('svg');
 }

 @font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-regular-400.eot'), '#{$fa-font-path}/fa-regular-400.eot'));
  src: url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-regular-400.eot#iefix'), '#{$fa-font-path}/fa-regular-400.eot#iefix')) format('embedded-opentype'),
  url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-regular-400.woff2'), '#{$fa-font-path}/fa-regular-400.woff2')) format('woff2'),
  url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-regular-400.woff'), '#{$fa-font-path}/fa-regular-400.woff')) format('woff'),
  url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-regular-400.ttf'), '#{$fa-font-path}/fa-regular-400.ttf')) format('truetype'),
  url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-regular-400.svg#fontawesome'), '#{$fa-font-path}/fa-regular-400.svg#fontawesome')) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: $fa-font-display;
  src: url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-brands-400.eot'), '#{$fa-font-path}/fa-brands-400.eot'));
  src: url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-brands-400.eot#iefix'), '#{$fa-font-path}/fa-brands-400.eot#iefix')) format('embedded-opentype'),
  url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-brands-400.woff2'), '#{$fa-font-path}/fa-brands-400.woff2')) format('woff2'),
  url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-brands-400.woff'), '#{$fa-font-path}/fa-brands-400.woff')) format('woff'),
  url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-brands-400.ttf'), '#{$fa-font-path}/fa-brands-400.ttf')) format('truetype'),
  url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}/fa-brands-400.svg#fontawesome'), '#{$fa-font-path}/fa-brands-400.svg#fontawesome')) format('svg');
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}
