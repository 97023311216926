// Variables
//------------------------------------------------------
$alert-primary:                 $aqua-light !default;
$alert-success:                 $grass-light !default;
$alert-danger:                  $grapefruit-light !default;
$alert-warning:                 $sunflower-light !default;

$alert-close-color:             $black !default;

$alert-link-color:              $mediumgray-light !default;


// Exports
//------------------------------------------------------

@include exports("alert") {

  /**
   * alert
   * --------------------------------------------------
   */
  .alert {

      & h4 {
          margin-bottom: 10px;
          font-weight: bold;
      }

      &-dismissable .close {
          color: $alert-close-color;
      }

      @at-root &-info {
          background-color: lighten($alert-primary, 10%);
          border: $alert-primary;
      }
      @at-root &-warning {
          background-color: lighten($alert-warning, 10%);
          border: $alert-warning;
      }
      @at-root &-danger {
          background-color: lighten($alert-danger, 10%);
          border: $alert-danger;
      }
      @at-root &-success {
          background-color: lighten($alert-success, 10%);
          border: $alert-success;
      }

      @at-root & &-link {
          cursor: pointer;
          text-decoration: underline;
          @include opacity($opacity: 65, $filter: true); 
          &:hover,
          &:focus {
              @include opacity($opacity: 45, $filter: true);            
          }
      }

      @at-root & .btn-link,
      & .btn-link:hover,
      & .btn-link:focus {
          color: $alert-close-color;
          @include opacity($opacity: 65, $filter: true);
      }
      @at-root & .btn-link:focus,
      & .btn-link:hover {
          text-decoration: none;
          @include opacity($opacity: 40, $filter: true);        
      }
  }

}


