// Variables
//------------------------------------------------------
$pricing-primary:                 $aqua-light !default;
$pricing-success:                 $grass-light !default;
$pricing-warning:                 $sunflower-light !default;

$pricing-foot-background-color:   $lightgray-dark !default;
$pricing-shadow:                  0 1px 2px rgba(0, 0, 0, .2) !default;
$pricing-shadow-active:           0 0 8px rgba(0, 0, 0, .6) !default;
$pricing-radius:                  4px !default;




// Exports
//------------------------------------------------------

@include exports("pricing") {

  /**
   * pricing
   * --------------------------------------------------
   */
  .pricing {

		& ul {
			list-style: none;
			padding: 0;
		}

		& .unit {
			position: relative;
			display: inline-block;
			text-align: center;
			min-width: 250px;
			@include radius($type: border-radius, $value: $pricing-radius);
			@include box-shadow($value: $pricing-shadow);

			&.active {
				top: 5px;
				margin-left: -36px;
				margin-right: -36px;
				z-index: 1;
				@include box-shadow($value: $pricing-shadow-active);

				& .price-title h3 {
					font-size: 40px;
				}
			}
		}

		@media screen and (max-width: 767px) {
			& .unit {
				display: block;
				margin-bottom: 20px;

				&.active {
					top: 0;
					margin-left: 0;
					margin-right: 0;

					& .price-title h3 {
						font-size: 30px;
					}
				}
			}
		}

		& .price-title {
			padding: 20px 20px 10px;
			@include radius($type: border-top-left-radius, $value: $pricing-radius);
			@include radius($type: border-top-right-radius, $value: $pricing-radius);
			color: #FFF;

			& h3,
		    & h3 > p {
				margin: 0;
			}
		}

		& .price-body {
			padding: 20px 20px 10px;

			& ul {
				padding-top: 10px;
			}

			& li {
				margin-bottom: 10px;
			}

			& h4 {
				margin: 0;
			}
		}

		& .price-foot {
			padding: 20px;
			@include radius($type: border-bottom-left-radius, $value: $pricing-radius);
			@include radius($type: border-bottom-right-radius, $value: $pricing-radius);

			background-color: $pricing-foot-background-color;
		}

		& .price-primary {
			& .price-title {
				background-color: $pricing-primary;
			}
			& .price-body {
				background-color: lighten($pricing-primary, 30%);
				& ul {
					border-top: 1px solid lighten($pricing-primary, 20%);
				}
			}
		}
		& .price-success {
			& .price-title {
				background-color: $pricing-success;
			}
			& .price-body {
				background-color: lighten($pricing-success, 30%);
				& ul {
					border-top: 1px solid lighten($pricing-success, 20%);
				}
			}
		}
		& .price-warning {
			& .price-title {
				background-color: $pricing-warning;
			}
			& .price-body {
				background-color: lighten($pricing-warning, 30%);
				& ul {
					border-top: 1px solid lighten($pricing-warning, 20%);
				}
			}
		}
	}
}
