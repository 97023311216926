// Variables
//------------------------------------------------------
$jumbotron-font-color:            $darkgray-dark !default;

$jumbotron-background-color:      $white !default;

$jumbotron-radius:                4px;
$jumbotron-shadow:                0 1px 2px rgba(0, 0, 0, .2) !default;


// Exports
//------------------------------------------------------

@include exports("jumbotron") {

  /**
   * jumbotron
   * --------------------------------------------------
   */
  .jumbotron {
    margin-bottom: 20px;
    padding: 0;
    @include radius($type: border-radius, $value: $jumbotron-radius);
    background-color: $jumbotron-background-color;
    @include box-shadow($value: $jumbotron-shadow);

    @at-root .container & {
      @include radius($type: border-radius, $value: $jumbotron-radius);      
    }

    & > &-photo img {
      @include radius($type: border-radius, $value: $jumbotron-radius $jumbotron-radius 0 0);       
      width: 100%;
    }

    & &-contents {
      padding: 20px;
      color: $jumbotron-font-color;
    }
    & .carousel,
    & .carousel-inner,
    & .carousel-inner > .item.active img {
      @include radius($type: border-radius, $value: $jumbotron-radius $jumbotron-radius 0 0); 
    }
    .carousel-inner > .item > a >img, 
    .carousel-inner > .item > img {
      width: 100%;
    }
    & .carousel-control.left {
      @include radius($type: border-radius, $value: $jumbotron-radius 0 0 0);       
    }
    & .carousel-control.right {
      @include radius($type: border-radius, $value: 0 $jumbotron-radius 0 0);       
    }

    & h1, & .h1,
    & h2, & .h2 {
      font-weight: 400;
    }

    & h1, & .h1 {
      font-size: 28px;
    }
    & h2, & .h2 {
      font-size: 24px;
    }
    & p {
      font-size: 14px;
    }

    @media screen and (min-width: 768px) {
      &,
      .container & {
        padding: 0;
      }
      & h1,
      & .h1 {
        font-size: 28px;
      }
    }
  }
}


