@charset "utf-8";

$font-family-base: Ubuntu;
$spacing-unit:     30px;
$fa-font-path: "/assets/fonts";

@import "bootstrap",
        "bootflat",
        "syntax-highlighting",
        "font-awesome",
        "overrides";
