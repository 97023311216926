// Variables
//------------------------------------------------------
$media-font-color:                $darkgray-dark !default;


$thumbnail-shadow:                0 1px 2px rgba(0, 0, 0, .2) !default;


// Exports
//------------------------------------------------------

@include exports("thumbnail") {

  /**
   * thumbnail
   * --------------------------------------------------
   */
  .thumbnail {
    border: none;
    @include box-shadow($value: $list-shadow);

    & a > img, & > img {
      width: 100%;
    }

    & .caption {
      font-size: 14px;
    }

    & .caption h1,
    & .caption h2,
    & .caption h3,
    & .caption h4,
    & .caption h5,
    & .caption h6 {
      margin: 5px 0 10px;
      font-size: 16px;
    }
  }

}


