// Variables
//------------------------------------------------------
$modal-font-color:            $darkgray-dark !default;

$modal-radius:                4px;
$modal-shadow:                0 1px 2px rgba(0, 0, 0, .2) !default;


// Exports
//------------------------------------------------------

@include exports("modal") {

  /**
   * modal
   * --------------------------------------------------
   */

  .modal {
    &-content {
      border: none;
      @include radius($type: border-radius, $value: $modal-radius);
      color: $modal-font-color; 
      @include box-shadow($value: $modal-shadow);
    }
    &-header {
      border-bottom: none;
    }
    &-body {
      padding: 0 15px;
    }
    &-footer {
      border-top: none;
    }
  }

}


